<template>
  <div class="home d-flex">
    <v-progress-linear
      :active="getLoaderStatus"
      indeterminate
      fixed
      top
      color="primary"
      height="5px"
      style="z-index: 500;"
    ></v-progress-linear>
    <payment-summary @closePaymentDialog="dialog = false" :dialog="dialog"></payment-summary>
    <vendor-listing @drawer="drawer" :mini="mini"></vendor-listing>
    <mice-navigation @vendor-list-mini="minimizeList"></mice-navigation>
    <top-nav-bar :mini="mini" :inactiveListings="inactiveListings" @pay="payNowDialog"></top-nav-bar>
    <v-main
      :class="{ 'detail-content-margin': listingDrawer, hideScroll: getToggleSlider }"
      :style="[mini ? { 'padding-left': '56px' } : { 'padding-left': '232px' }]"
      class="details-content"
    >
      <hotel-details v-if="getActiveVendorType === 'hotel'"></hotel-details>
      <location-details v-else-if="getActiveVendorType === 'location'"></location-details>
    </v-main>
  </div>
</template>

<script>
const TopNavBar = () => import(/* webpackChunkName: "top-nav-bar" */ "@/components/TopNavBar");
const VendorListing = () => import(/* webpackChunkName: "vendor-listing" */ "./VendorListing");
const HotelDetails = () => import(/* webpackChunkName: "hotel-details" */ "./hotels/HotelDetails");
const LocationDetails = () => import(/* webpackChunkName: "location-details" */ "./locations/LocationDetails");
const PaymentSummary = () => import(/* webpackChunkName: "payment-summary" */ "@/components/PaymentSummary");
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  components: { TopNavBar, VendorListing, HotelDetails, LocationDetails, PaymentSummary },
  data: () => ({
    mini: false,
    navigation: 0,
    listingDrawer: true,
    inactiveListings: true,
    dialog: false
  }),
  computed: {
    ...mapGetters(["getOverlayStatus", "getActiveVendorType", "getLoaderStatus", "getAuthUser", "getToggleSlider"])
  },
  async created() {
    await this.loadAccounts();
    await this.loadVendors({ status: null, type: null, org: null });
    this.eventsList();
  },
  async mounted() {
    await this.loadAllDrafts();
  },
  methods: {
    drawer(value) {
      this.listingDrawer = value;
    },
    eventsList() {
      let userType = this.getAuthUser.user_type;
      let userAccountId = this.getAuthUser.account_id;
      let adminAccountId = this.getAuthUser.id;

      if (userType === "seller") {
        let vendorAccountChannel = Echo.channel(`miceloc_vendor.${userAccountId}`);
        // Vendor Created
        vendorAccountChannel.listen(".vendor.create", e => {
          this.$store.dispatch("loadOrganizations");
          this.$store.commit("updateVendorList", e.vendor);
        });

        // Vendor Updated
        vendorAccountChannel.listen(".vendor.update", e => {
          this.$store.dispatch("loadOrganizations");
          this.$store.commit("updateVendorList", e.vendor);
        });
      } else {
        let vendorAccountChannel = Echo.channel(`miceloc_vendor.${adminAccountId}`);
        // Vendor Created
        vendorAccountChannel.listen(".vendor.create", e => {
          this.$store.dispatch("loadOrganizations");
          this.$store.commit("updateVendorList", e.vendor);
        });

        // Vendor Updated
        vendorAccountChannel.listen(".vendor.update", e => {
          this.$store.dispatch("loadOrganizations");
          this.$store.commit("updateVendorList", e.vendor);
        });
      }
    },
    payNowDialog() {
      this.dialog = true;
    },
    minimizeList(status) {
      this.mini = status;
    },
    ...mapActions(["loadVendors", "loadAllDrafts", "loadAccounts"])
  }
};
</script>

<style scoped>
#listing {
  width: 580px;
}
.hideScroll {
  display: none;
}
.details-content {
  width: calc(100vw - 480px);
  height: 100vh;
  background-color: white;
}
.detail-content-margin {
  margin-left: 480px;
}
</style>
